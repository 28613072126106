import { SwapWidget, Theme } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'

import styles from '../styles/Home.module.css'
import { JSON_RPC_URL } from '../constants'
import { useEffect, useState } from 'react'

const UNISWAP_TOKEN_LIST_URL = 'https://ipfs.io/ipns/tokens.uniswap.org'

const TOKEN_DETAILS = {
  name: 'Pepe Unchained',
  address: '0xadd39272E83895E7d3f244f696B7a25635F34234',
  symbol: 'PEPU',
  decimals: 18,
  chainId: 1,
  logoURI: 'https://d255lrf1vntaa9.cloudfront.net/assets/images/svg-icons/token.svg',
}

const theme: Theme = {
  primary: '#0D111C',
  secondary: '#5D6785',
  interactive: '#E8ECFB',
  container: '#FFF',
  module: '#F5F6FC',
  accent: '#2EB335',
  outline: '#CADDC2',
  dialog: '#FFF',
  fontFamily: 'Grandstander',
}

export default function App() {
  const [tokenList, setTokenList] = useState<any[]>([])

  const fetchTokenListData = async () => {
    const response = await fetch(UNISWAP_TOKEN_LIST_URL)
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`)
    }

    const json = await response.json()
    setTokenList([...json.tokens, TOKEN_DETAILS])
  }
  useEffect(() => {
    fetchTokenListData()
  }, [])

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <div className={styles.demo}>
          <div className={styles.widget}>
            <SwapWidget
              className='swap-widget'
              width={'100%'}
              jsonRpcUrlMap={{ 1: [JSON_RPC_URL.eth as string]}}
              theme={theme}
              tokenList={tokenList}
              defaultInputTokenAddress={'NATIVE'}
              defaultOutputAmount="1"
              defaultOutputTokenAddress={TOKEN_DETAILS.address}
            />
          </div>
        </div>
      </main>
    </div>
  )
}
